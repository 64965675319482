import React, { useMemo, useState } from "react"
import { Link } from "react-router-dom"

import Header from 'components/Header'
import { FileInput, PhoneInput } from '../components/Inputs/Inputs'

import TextInput from 'components/Inputs/OldTextInput'
import TextareaInput from "components/Inputs/OldTextarea"
import axios from 'axios'
import { toast } from "react-toastify"
import FullLoadingPage from '../components/FullPageLoading'
import Validations from '../utils/validations'


function ContactPage () {

	const [contactStatus, setContactStatus] = useState(0)
	const [formData, setFormData] = useState({})
	const [errors, setErrors] = useState({})

	const handleInputChange = e => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
		setErrors({ ...errors, [e.target.name]: undefined })
	}

	const validations = useMemo(() => {
		return {
			"lastName": () => Validations.validString(formData.lastName, { minLength: 3, maxLength: 150 }),
			"firstName": () => Validations.validString(formData.firstName, { minLength: 3, maxLength: 150 }),
			"phone": () => Validations.phone(formData.phone),
			"email": () => Validations.email(formData.email),
			"message": () => Validations.validString(formData.message, { minLength: 3, maxLength: 500 })
		}
	}, [formData])

	const handleSubmit = e => {
		e.preventDefault()

		let _errors = {}

		let firstErrorInput = false

		Object.keys(validations).map(validationKey => {

			let error = validations[validationKey]()

			if (error) {
				firstErrorInput = validationKey
				_errors[validationKey] = [error]
			}

			return null

		})

		if (firstErrorInput) {
			let errorElem = document.querySelector("[name=" + firstErrorInput + "]")
			if (errorElem) {
				let top = errorElem.getBoundingClientRect().top + window.scrollY - 100
				window.scroll({ top, behavior: "smooth" })
			}
		}

		if (Object.keys(_errors).length > 0) {
			setErrors(_errors)
			return false
		}

		setContactStatus(1)

		let newFormData = new FormData()

		newFormData.append("lastName", formData.lastName)
		newFormData.append("firstName", formData.firstName)
		newFormData.append("phone", formData.phone)
		newFormData.append("email", formData.email)
		newFormData.append("message", formData.message)
        if (formData.file) {
            newFormData.append("file", formData.file)
        }

		axios.post("/api/public/contact", newFormData).then(() => {
			setContactStatus(2)
		}).catch(() => {
			setContactStatus(0)
            toast.error("Une erreur est survenue, veuillez écrire à service.informatique@cssb.fr")
		})
	}

	return (
		<>
			<Header />

			<FullLoadingPage show={contactStatus === 1} />

			<main className="contact-page center">

				<div className="full-page-card">

					{contactStatus === 0 &&
                        <div className="blue-title">Formulaire de Contact</div>
                    }

					<form className="contact-form" onSubmit={contactStatus === 0 ? handleSubmit : () => {}}>

						{contactStatus === 2 &&
							<>
								<div className="blue-title">
									Votre message à bien été envoyé
								</div>

								<div className="card-content text-left">
									Nous ferons tout pour vous répondre au plus vite
								</div>

                                <Link to="/" className="btn outline-btn contact-btn">
                                    Retour à la page d'accueil
                                </Link>
							</>
						}

						{contactStatus !== 2 &&
							<>
								<div className="card-content text-left">

									<div className="flex flex-space-between">

										<div className="flex flex-1">

											<TextInput
												className="flex-1 mr-30"
												label="Nom"
												name="lastName"
												onChange={handleInputChange}
												value={formData.lastName}
												errors={errors.lastName}
											/>

										</div>

										<div className="flex flex-1">

											<TextInput
												className="flex-1 ml-30"
												label="Prénom"
												name="firstName"
												onChange={handleInputChange}
												value={formData.firstName}
												errors={errors.firstName}
											/>

										</div>

									</div>

									<div className="flex flex-space-between">

										<div className="flex flex-1">

											<PhoneInput
												className="flex-1 mr-30"
												label="Téléphone"
												name="phone"
												onChange={handleInputChange}
												value={formData.phone ? formData.phone : ""}
												errors={errors.phone}
											/>

										</div>

										<div className="flex flex-1">

											<TextInput
												className="flex-1 ml-30"
												label="Email"
												name="email"
												onChange={handleInputChange}
												value={formData.email}
												errors={errors.email}
											/>

										</div>
									</div>

									<div className="flex flex-space-around">
										<div className="flex-1">
											<TextareaInput
												label="Votre message"
												name="message"
												onChange={handleInputChange}
												value={formData.message}
												errors={errors.message}
											/>
										</div>
									</div>

									<div className="flex flex-space-around">
										<div className="flex-1">

											<FileInput
												className="mt-30"
												title="Fichier (facultatif)"
												name="file"
												subtitle="Pour illustrer votre propos"
												fileExt={[".png", ".jpg", ".jpeg"]}
												onChange={handleInputChange}
												errors={errors.file}
												value={formData.file}
												maxSize={5 * 1024 * 1024}
											/>

										</div>
									</div>

								</div>

								<div className="card-bottom">

									<div className="form-btn-group">
										<button type="submit" className="btn">Envoyer</button>
									</div>

								</div>
							</>
						}

					</form>

				</div>

			</main>
		</>
	)
}

export default ContactPage