import React, { useState, useEffect } from "react";
import axios from "axios";

import DashHeader from 'components/Dash/Header'
import DashSidebar from 'components/Dash/Sidebar'

import Table from "components/Table";

import FullPageLoading from "components/FullPageLoading"
import { Collapsible } from "components/utils/dev"

export default function MyEvents({
    history
}) {

    const [isLoading, setIsLoading] = useState(false)
    const [headers, setHeaders] = useState()
    const [events, setEvents] = useState()

    useEffect(() => {
        setIsLoading(true)
        axios.get("/api/events/my-events" 
        ).then(res => {
            setHeaders(res.data.headers);
            setEvents(res.data.events);
        }).catch(err => {
            console.log("Error ", err)
        }).finally(() => setIsLoading(false))
    }, []);

	return (
		<div className="admin-page">

            <FullPageLoading show={isLoading}/>

			<DashSidebar current="my-events" />

			<DashHeader paths={[
                {
					name: "Mes Inscriptions",
					url: "/dashboard/my-events"
				},
			]} />

			<div className="admin-body">

                <div className="admin-section">
					<em>Listes des évènements disponible :</em>
                    <ul>
						<li> inscriptions à des camps d'été, stages (CSAO, ...), journée porte ouvertes, ... </li>
                        <li> adhésions à l'année dans une compagnie d'active </li>
                        <li> boutiques en lignes lors d'évènements particulier </li>
                    </ul>
                    <em>Cliquez sur la ligne correspondante dans le tableau ci-dessous pour accéder à la page de l'évènement</em>
				</div>

                <div>
                    <strong>Important :</strong>  
                    <ul>
                        <li>l'inscription à certains évènements (camp, ...) est 
                        autorisée uniquement si toutes les fiches membres des personnes pouvant s'inscrire sont complètes, 
                        mises-à-jour, et validées ... (status OK dans "Mes Données")</li>
                        <li>vous ne verrez rien s'il n'y a aucun évènement actif auquel vous pouvez vous inscrire personnellement
                            (pour les secrétaires, voir l'onglet <strong>Évènements</strong> pour la liste des évènements actuels)
                        </li>
                    </ul>
                </div>

                {headers && events &&
                <Table
                    uniqueID="my-events"
                    headers={headers}
                    data={events}
                    onItemClicked={event => history.push(`/dashboard/my-events/${event.eID}`)}
                />}

                {/* For development and debug ... */}
                <Collapsible header="headers" data={headers} />
                <Collapsible header="events" data={events} />

			</div>

			

		</div>
	)
}