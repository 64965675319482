import React from "react"

import DashHeader from 'components/Dash/Header'
import DashSidebar from 'components/Dash/Sidebar'

export default function Changelog() {

	return (
<div className="admin-page">

    <DashSidebar current="changelog" />

    <DashHeader paths={[
        {
            name: "Mises à jour",
            url: "/dashboard/changelog"
        }
    ]} />

    <div className="admin-body">
        <div className="member">
            Liste des changements apportés au site, en commençant par les plus récents ...

            <section className="member-basic">
                <div className="change">
                    <em>7 Octobre 2024</em> :
                    <ul>
                        <li> optimization du temps d'affichage des pages relatives aux évènements </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>4 Octobre 2024</em> :
                    <ul>
                        <li> correction du bug empêchant l'utilisation du formulaire de contact (utilisable sans connexion)
                        </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>21 Septembre 2024</em> :
                    <ul>
                        <li> correction du bug empêchant l'utilisation du formulaire d'aide par les comptes parents
                        </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>18 Juillet 2024</em> :
                    <ul>
                        <li> ajout des fiches sanitaires de liaison dans le module assistant sanitaire
                        </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>16 Juillet 2024</em> :
                    <ul>
                        <li> ajout des fiches de présence pour les évènements de type camp
                        </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>2 Juillet 2024</em> :
                    <ul>
                        <li> ajout du module de suivi médical pour l'assistant sanitaire
                        </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>8 Juin 2024</em> :
                    <ul>
                        <li> aperçu du total des paiements sur la page d'administration des évènements, avec un lien direct HelloAsso 
                        </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>24 Mai 2024</em> : <strong>Résolution ! </strong>
                    <ul>
                        <li> suite à un retour du service technique d'Orange qui ont corrigé le problème de leur côté, 
                            et aux accusés de réception de mail de test envoyé à certains parents ayant des adresses 
                            @orange.fr ou @wanadoo.fr, le problème de réception des mails envoyés par Central est désormais résolu
                        </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>20 Mai 2024</em> : <strong>Attention !</strong>
                    <ul>
                        <li> depuis le 1er Mai, les mails envoyés par Central aux adresses @orange.fr et @wanadoo.fr sont systématiquement rejetées : les 
                            familles ayant ce type d'adresse ne peuvent recevoir ni notifications, ré-initialisation du mot de passe, etc ... 
                        </li>
                        <li> un problème de formatage des mails a été résolu le 18 Mai, et les mails envoyés avec le programme utilisé par Central 
                            sont bien reçus. Mais ceux envoyés par ce même programme depuis le serveur hébergeant Central sont rejetés systématiquement.
                            Des analyses sont en cour ...
                        </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>18 Mai 2024</em>
                    <ul>
                        <li> 
                            envoi d'un email de validation lorsqu'une fiche membre est passée en status OK (pour les fratries,
                            le mail est envoyé uniquement lorsque toutes les fiches sont OK) 
                        </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>17 Mai 2024</em>
                    <ul>
                        <li> 
                            aperçu des membres inscrits pour chaque évènements, accessible aux secrétaires, chefs et directeurs de camp
                            (avec restriction d'accès par compagnie, comme pour les listings)
                        </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>15 Mai 2024</em>
                    <ul>
                        <li> 
                            ajout d'un bouton pour ajouter un nouveau membre, pour l'instant restreint au secrétariat général 
                            et aux directeurs de camp (utilisation pour l'inscription des nouveaux) 
                        </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>5 Mai 2024</em>
                    <ul>
                        <li> 
                            ajout de la fonctionnalité d'inscription à des évènements, avec différents modes de paiements possibles
                            (HelloAsso, Chèques, Chèques Vacance, Parrainage)
                        </li>
                        <li> 
                            inscriptions ouvertes pour le camp d'été 2024 et le trajet en bus Paris-Saintes (aller-retour)
                        </li>
                        <li> 
                            détection des paiements effectués sur HelloAsso et validation automatique des inscriptions
                            aux évènements concernés
                        </li>
                        <li> 
                            couplage de Central avec le serveur Discord CSSB, avec envoi des notifications d'erreur, 
                            de formulaire de contact et de support
                        </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>6 Avril 2024</em>
                    <ul>
                        <li> 
                            ajout de la barre de recherche et des filtres dans les listings de compagnies
                        </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>5 Avril 2024</em> : <strong>Lancement officiel de Central</strong>
                    <ul>
                        <li> 
                            création d'un compte pour chaque parent de jeune, chefs, secrétaire et directeur de camp (~220 comptes)
                        </li>
                        <li> 
                            importation automatique des données présentes sur les listings de compagnies 
                            (Google Drive) et celle de l'ancien site d'inscription pour le camp d'été 2023
                        </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>9 Mars 2024</em>
                    <ul>
                        <li> 
                            mise en ligne de Central dans sa version de développement, avec déploiement automatique via son répertoire GitHub
                        </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>4 Janvier 2024</em>
                    <ul>
                        <li> 
                            mise en place des tests en continue effectué à chaque modification de la branche principale du code
                        </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>31 Octobre 2023</em>
                    <ul>
                        <li> 
                            fonctionnalités pour créer un compte, unicité du matricule, distinction entre membre et compte d'accès
                        </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>30 Septembre 2023</em>
                    <ul>
                        <li> 
                            mise en place du système d'authentification sécurisée via une double clef de chiffrement 
                        </li>
                    </ul>
                </div>
            </section>

            <section className="member-basic">
                <div className="change">
                    <em>31 Juillet 2023</em> : <strong>Genèse de Central</strong>
                    <ul>
                        <li> 
                            première version du code hébergée sur un répertoire GitHub en accès privé
                        </li>
                    </ul>
                </div>
            </section>

        </div>
    </div>

    

</div>
	)
}